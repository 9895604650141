import { MenuItemInterface } from '@store/types'

export enum MenuItemKey {
  Residences = 'residences',
  InteractiveMap = 'interactive-map',
  Videos = 'videos',
  Gallery = 'gallery',
  Brochure = 'brochure',
  Team = 'team',
  ExternalRoutes = 'external-routes',
  Shortlist = 'shortlist',
}

const MENU_ITEMS: Array<MenuItemInterface> = [
  {
    key: MenuItemKey.Residences,
    label: 'RESIDENCES',
    route: 'area-view',
    relatedRoutes: [
      'area-view',
      'level-view',
      'building',
      'snaploader-view',
      '3d-building',
      'panoramic-view',
      'panoramic',
      'areaview-3d',
      'area-view-house-and-land',
      'precinct',
      'stages',
    ],
    active: true,
  },
  {
    key: MenuItemKey.InteractiveMap,
    label: 'MAP',
    route: 'interactive-map',
    relatedRoutes: ['interactive-map'],
    active: true,
  },
  {
    key: MenuItemKey.Videos,
    label: 'VIDEOS',
    route: 'video-gallery',
    relatedRoutes: ['video-gallery'],
    active: true,
  },
  {
    key: MenuItemKey.Gallery,
    label: 'GALLERY',
    route: 'gallery',
    relatedRoutes: ['gallery'],
    active: true,
  },
  {
    key: MenuItemKey.Brochure,
    label: 'BROCHURE',
    route: 'brochure',
    relatedRoutes: ['brochure'],
    active: true,
  },
  {
    key: MenuItemKey.Team,
    label: 'TEAM',
    route: 'teams',
    relatedRoutes: ['teams'],
    active: true,
  },
  {
    key: MenuItemKey.ExternalRoutes,
    label: 'EXTERNAL LINKS',
    route: 'external-views',
    relatedRoutes: ['external-views'],
    active: true,
  },
  {
    key: MenuItemKey.Shortlist,
    label: 'SHORTLIST',
    route: 'shortlist',
    relatedRoutes: ['shortlist'],
    active: true,
  },
]

export default MENU_ITEMS
