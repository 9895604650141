import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import { setExternalViews } from '@store/actionSlices/externalViews'
import {
  ConfigInterface,
  setAspect,
  setBedConfig,
  setDisableUnitSoldInfo,
  setEnvisionVRConfiguration,
  setGallerySettings,
  setHouseAndLandConfig,
  setLightUpBuildingModelOnFilter,
  setMarkerColour,
  setNavigationSettings,
  setPrices,
  setProjectConfig,
  setProjectType,
  setSnaploaderConfiguration,
  setStatusColors,
  setStatusLabels,
  setThemeConfiguration,
  setThreeDViewerUrl,
  setUnitTypes,
} from '@store/actionSlices/projectConfig'
import { ShortlistState } from '@store/actionSlices/shortlist'
import { setWhiteLabel } from '@store/actionSlices/whiteLabel'
import { MenuItemInterface, RootStateTypeExtra, SessionMap } from '@store/types'

import ImageHandler from '@components/image-handler'

import {
  selectFromResult as selectFromResultConfig,
  useGetConfigQuery,
} from '@api/config'
import {
  ExternalViewsData,
  selectFromResult as selectFromExternalViewsResult,
  useGetExternalViewsQuery,
} from '@api/external-views'
import {
  WhiteLabel,
  selectFromWhiteLabelResult,
  useGetWhiteLabelsQuery,
} from '@api/white-label'

import { ProjectType } from '@utilities/adgroup-utilities/constants/common'
import { hasEnvisionVR, hasSnaploader } from '@utilities/helper'
import useHiddenNavs from '@utilities/use-hidden-navs'

import { DefaultLogoSvg, StarSvg } from '@svg/react'

import { FULFILLED_STATUS } from '../data-handler/data-handler'
import ExternalMenuItems from './external-menu-items'
import MENU_ITEMS from './menu-items'

interface ComponentProps {
  projectName: string
  projectConfig: ConfigInterface
  externalViews: Array<ExternalViewsData>
  forceFullScreenMode?: boolean
  shortlists: ShortlistState['shortlists']
  session: SessionMap | undefined
  whiteLabel: WhiteLabel
}

function Navigation({
  projectName,
  projectConfig,
  externalViews,
  forceFullScreenMode,
  shortlists,
  session,
  whiteLabel,
}: ComponentProps) {
  const dispatch = useDispatch()
  const location = useLocation()

  const hiddenNavs = useHiddenNavs()

  const [activeMenu, setActiveMenu] = React.useState('')
  const [externalMenuItemsToggle, setExternalMenuItemsToggle] =
    React.useState(false)
  const [skipExternalViewsApi, setSkipExternalViewsApi] = React.useState(true)

  const fullScreenMode = React.useMemo(() => {
    if (forceFullScreenMode !== undefined) return forceFullScreenMode
    return !!session?.connected
  }, [session, forceFullScreenMode])

  const {
    isLoaded,
    prices,
    configInfo,
    threeDViewer,
    markerColour,
    snaploaderConfiguration,
    envisionVRConfiguration,
    bedConfig,
    houseAndLandConfig,
    aspects,
    unitTypes,
    theme,
    navigationSettings,
    status,
    gallerySettings,
    disableSoldUnit,
    type: projectType,
    lightUpBuildingModelOnFilter,
    statusLabels,
    statusColors,
  } = useGetConfigQuery(
    { projectName },
    { selectFromResult: selectFromResultConfig }
  )

  const whiteLabelPayload = useGetWhiteLabelsQuery(
    { projectName },
    { selectFromResult: selectFromWhiteLabelResult }
  )

  const externalViewsPayload = useGetExternalViewsQuery(
    { projectName },
    {
      selectFromResult: selectFromExternalViewsResult,
      skip:
        navigationSettings.find(
          (item: MenuItemInterface) =>
            item.key === 'external-routes' && item.active
        ) === undefined,
    }
  )

  const navigationItems = React.useMemo(() => {
    if (!isLoaded) {
      return []
    }

    if (navigationSettings.length < 1) {
      return MENU_ITEMS
    }

    return MENU_ITEMS.map((item: MenuItemInterface) => {
      const foundItem = navigationSettings.find(
        (navigationSetting: MenuItemInterface) =>
          navigationSetting.key === item.key
      )

      return foundItem
        ? {
            ...item,
            label: foundItem.label,
            active: foundItem.active,
            order: foundItem.order,
          }
        : item
    })
      .sort((a, b) => {
        if (!Number.isNaN(a.order ?? NaN) && !Number.isNaN(b.order ?? NaN)) {
          return Number(a.order) - Number(b.order)
        }
        return MENU_ITEMS.indexOf(a) - MENU_ITEMS.indexOf(b)
      })
      .filter((item: MenuItemInterface) => !!item.active)
  }, [isLoaded, navigationSettings])

  const shortlistMenuItem = React.useMemo(
    () =>
      navigationItems.find(
        (item: MenuItemInterface) => item.key === 'shortlist'
      ),
    [navigationItems]
  )

  const getRouteLink = (argNavItem: MenuItemInterface): string => {
    if (argNavItem.key !== 'residences') {
      return argNavItem.route || ''
    }

    const { threeDViewerUrl, type } = projectConfig

    if (hasSnaploader(projectConfig.snaploaderConfiguration)) {
      return 'snaploader-view'
    }

    if (hasEnvisionVR(projectConfig.envisionVRConfiguration)) {
      return '3d-building'
    }

    if (threeDViewerUrl !== '') {
      return 'areaview-3d'
    }

    if (type === ProjectType.HouseAndLand) {
      return 'area-view-house-and-land'
    }

    return 'area-view'
  }

  React.useEffect(() => {
    const { externalViewsData, isLoaded: isExternalViewLoaded } =
      externalViewsPayload
    if (
      isExternalViewLoaded &&
      externalViewsData.length > 0 &&
      externalViews.length === 0
    ) {
      dispatch(setExternalViews(externalViewsData))
    }
  }, [externalViewsPayload])

  React.useEffect(() => {
    if (status === FULFILLED_STATUS) {
      const { min, max } = prices
      if (min && min.length >= 0 && max && max.length >= 0) {
        dispatch(setPrices(prices))
      }

      if (markerColour && Object.values(markerColour).length > 0) {
        dispatch(setMarkerColour(markerColour))
      }

      const { threeDViewerUrl } = projectConfig
      if (threeDViewer?.active && !threeDViewerUrl) {
        dispatch(setThreeDViewerUrl(threeDViewer.url))
      }

      if (Object.keys(configInfo).length > 0) {
        dispatch(setProjectConfig({ ...configInfo }))
      }

      if (
        snaploaderConfiguration &&
        Object.values(snaploaderConfiguration).length > 0
      ) {
        dispatch(setSnaploaderConfiguration(snaploaderConfiguration))
      }

      if (
        envisionVRConfiguration &&
        Object.values(envisionVRConfiguration).length > 0
      ) {
        dispatch(setEnvisionVRConfiguration(envisionVRConfiguration))
      }

      if (bedConfig && Object.keys(bedConfig).length > 0) {
        dispatch(setBedConfig(bedConfig))
      }

      if (houseAndLandConfig && Object.keys(houseAndLandConfig).length > 0) {
        dispatch(setHouseAndLandConfig(houseAndLandConfig))
      }

      if (aspects && aspects.length >= 0) {
        dispatch(setAspect(aspects))
      }

      if (navigationSettings && navigationSettings.length >= 0) {
        dispatch(setNavigationSettings(navigationSettings))
      }

      if (unitTypes && unitTypes.length >= 0) {
        dispatch(setUnitTypes(unitTypes))
      }

      if (theme && Object.values(theme).length > 0) {
        dispatch(setThemeConfiguration(theme))
      }

      if (statusLabels && Object.values(statusLabels).length > 0) {
        dispatch(setStatusLabels(statusLabels))
      }

      if (statusColors && Object.values(statusColors).length > 0) {
        dispatch(setStatusColors(statusColors))
      }

      if (gallerySettings && Object.values(gallerySettings).length > 0) {
        dispatch(setGallerySettings(gallerySettings))
      }

      if (projectType) {
        dispatch(setProjectType(projectType))
      }

      dispatch(setDisableUnitSoldInfo(disableSoldUnit))
      dispatch(setLightUpBuildingModelOnFilter(lightUpBuildingModelOnFilter))

      if (
        externalViews.length === 0 &&
        skipExternalViewsApi &&
        navigationSettings.find((item) => item.key === 'external-views')
      ) {
        setSkipExternalViewsApi(false)
      }
    }
  }, [status])

  React.useEffect(() => {
    const { whiteLabelData, isLoaded } = whiteLabelPayload
    if (
      Object.values(whiteLabel).length === 0 &&
      isLoaded &&
      Object.values(whiteLabelData).length > 0
    ) {
      dispatch(setWhiteLabel(whiteLabelData))
    }
  }, [whiteLabelPayload])

  React.useEffect(() => {
    const paths = location.pathname.split('/')
    setActiveMenu(paths[2] || '')
  }, [location])

  return (
    <nav
      className={`absolute flex h-9 w-full items-center justify-between bg-neutralColour px-8 text-xs font-semibold tracking-widest transition-all duration-300 ${
        fullScreenMode ? '-top-9' : 'top-0 z-20'
      }`}
    >
      <div>
        <Link
          title="Vision"
          to={{
            pathname: `/${projectName}/vision`,
            state: { from: location.pathname },
          }}
        >
          {projectConfig?.logo?.visible && projectConfig?.logo?.src ? (
            <ImageHandler
              url={projectConfig.logo.src}
              type="legacy"
              className="h-7"
              alt="logo"
              showFallbackImage={false}
              isImage
            />
          ) : (
            <DefaultLogoSvg className="h-7 w-7" />
          )}
        </Link>
      </div>

      <ul className="absolute left-1/2 top-1/2 z-30 mx-auto flex -translate-x-1/2 -translate-y-1/2 cursor-pointer items-center bg-neutralColour uppercase">
        {navigationItems
          .filter(
            (item: MenuItemInterface) =>
              item.key !== 'shortlist' && !hiddenNavs.includes(item.key)
          )
          .map((navItem: MenuItemInterface) =>
            navItem.key === 'external-routes' ? (
              <li className="relative shrink-0" key={navItem.key}>
                <button
                  title={navItem.label}
                  type="button"
                  className={`nav-menu-item uppercase ${
                    navItem.relatedRoutes?.includes(activeMenu)
                      ? 'bg-secondaryColour'
                      : ''
                  }`}
                  onMouseEnter={() => setExternalMenuItemsToggle(true)}
                  onMouseLeave={() => setExternalMenuItemsToggle(false)}
                >
                  {navItem.label}
                </button>
                <ExternalMenuItems
                  externalViews={externalViews}
                  externalMenuItemsToggle={externalMenuItemsToggle}
                  setExternalMenuItemsToggle={setExternalMenuItemsToggle}
                />
              </li>
            ) : (
              <li className="shrink-0" key={navItem.key}>
                <Link
                  title={navItem.label}
                  className={`nav-menu-item ${
                    navItem.relatedRoutes?.includes(activeMenu)
                      ? 'bg-secondaryColour'
                      : ''
                  }`}
                  to={{
                    pathname: `/${projectName}/${getRouteLink(navItem)}`,
                    state: { from: location.pathname },
                  }}
                >
                  {navItem.label}
                </Link>
              </li>
            )
          )}
      </ul>

      {shortlistMenuItem && !hiddenNavs.includes('shortlist') && (
        <div className="flex items-center">
          <Link
            title={shortlistMenuItem.label}
            className="inline-flex items-center"
            to={{
              pathname: shortlistMenuItem.route,
              state: { from: location.pathname },
            }}
          >
            <div className="mr-2 rounded-full bg-secondaryColour p-1">
              <StarSvg
                className={`h-2.5 w-2.5 stroke-mainColour ${
                  shortlists.length > 0 ? 'fill-mainColour' : 'fill-transparent'
                }`}
              />
            </div>
            <span className="font-semibold uppercase text-mainColour">
              {shortlists.length > 0
                ? `${shortlists.length} SHORTLISTED`
                : shortlistMenuItem.label}
            </span>
          </Link>
        </div>
      )}
    </nav>
  )
}

export default connect(
  ({
    projectIdentity: { projectName },
    projectConfig,
    externalViews,
    shortlist: { shortlists },
    firestore: { session },
    whiteLabel,
  }: RootStateTypeExtra) => ({
    session,
    projectName,
    projectConfig,
    externalViews,
    shortlists,
    whiteLabel,
  })
)(Navigation)
