import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { ThemeConfigInterface } from '@api/config'

import { getQueryStringParams } from '@utilities/helper'

const FONT_DEFAULT = 'TT Commons'
const STORAGE_KEY_THEME = 'theme'
const STORAGE_KEY_THEME_OBJECT = 'themeObject'
const BUCKET_URL = process.env.REACT_APP_BUCKET
const FONT_WEIGHTS = {
  Light: 400,
  Regular: 500,
  Medium: 600,
  DemiBold: 700,
}

const parseJSON = (data: string) => {
  try {
    return JSON.parse(data)
  } catch (error) {
    return null
  }
}

const sanitizeColor = (colour: string): string => {
  if (!colour) {
    return ''
  }
  if (colour && colour.match(/[0-9A-Fa-f]{6}/g)) {
    return `${colour}`
  }
  return colour
}

const mergeThemeConfig = (
  parsedParams: Partial<ThemeConfigInterface>,
  storedConfig: Partial<ThemeConfigInterface>,
  projectConfig: ThemeConfigInterface
): ThemeConfigInterface => ({
  font:
    parsedParams.font ||
    storedConfig.font ||
    projectConfig.font ||
    FONT_DEFAULT,
  mainColour: sanitizeColor(
    parsedParams.mainColour ||
      storedConfig.mainColour ||
      projectConfig.mainColour
  ),
  secondaryColour: sanitizeColor(
    parsedParams.secondaryColour ||
      storedConfig.secondaryColour ||
      projectConfig.secondaryColour
  ),
  tertiaryColour: sanitizeColor(
    parsedParams.tertiaryColour ||
      storedConfig.tertiaryColour ||
      projectConfig.tertiaryColour
  ),
  neutralColour: sanitizeColor(
    parsedParams.neutralColour ||
      storedConfig.neutralColour ||
      projectConfig.neutralColour
  ),
})

const generateFontFaceStyles = (font: string): string =>
  Object.entries(FONT_WEIGHTS)
    .map(([weight, value]) => {
      const formattedFontName = font.replace(/ /g, '_')
      return `
        @font-face {
          font-family: '${font}';
          src: url('${BUCKET_URL}/font-ttf/${formattedFontName}_${weight}.ttf') format('truetype');
          font-weight: ${value};
          font-style: normal;
        }
      `
    })
    .join('')

const generateDynamicStyle = (
  themeObject: ThemeConfigInterface,
  resetTheme: boolean
): string => {
  const themeFromLocalStorage = !resetTheme
    ? localStorage.getItem(STORAGE_KEY_THEME)
    : undefined

  const { font, mainColour, secondaryColour, tertiaryColour, neutralColour } =
    themeObject

  const fontFaceStyles = generateFontFaceStyles(font)

  return (
    themeFromLocalStorage ??
    `
    ${fontFaceStyles}

    * {
      font-family: '${font}', sans-serif;
    }

    :root {
      --main-colour: ${mainColour};
      --secondary-colour: ${secondaryColour};
      --tertiary-colour: ${tertiaryColour};
      --neutral-colour: ${neutralColour};
    }
  `
  )
}

function styleUtil(
  themeProjectConfig?: ThemeConfigInterface
): ThemeConfigInterface {
  const location = useLocation()
  const { themeParam, resetTheme } = getQueryStringParams(location.search)

  const parsedThemeParam = parseJSON(themeParam || '{}')

  const storedThemeConfig = parseJSON(
    localStorage.getItem(STORAGE_KEY_THEME_OBJECT) || '{}'
  )

  const projectThemeConfig = useMemo(
    () =>
      themeProjectConfig ??
      parseJSON(localStorage.getItem('projectConfig') || '{}')?.theme ??
      {},
    [themeProjectConfig]
  )

  const themeObject = useMemo(
    () =>
      mergeThemeConfig(parsedThemeParam, storedThemeConfig, projectThemeConfig),
    [parsedThemeParam, storedThemeConfig, projectThemeConfig]
  )

  useEffect(() => {
    if (!themeObject.mainColour) {
      return
    }

    const style = document.getElementById('dynamic-theme')

    const dynamicStyle = generateDynamicStyle(themeObject, resetTheme)

    if (themeParam) {
      localStorage.setItem(
        STORAGE_KEY_THEME_OBJECT,
        JSON.stringify(themeObject || {})
      )
      localStorage.setItem(STORAGE_KEY_THEME, dynamicStyle)
    }

    if (style) {
      style.innerHTML = dynamicStyle
    }
  }, [themeProjectConfig, themeParam, resetTheme])

  useEffect(() => {
    if (resetTheme) {
      localStorage.removeItem(STORAGE_KEY_THEME)
      localStorage.removeItem(STORAGE_KEY_THEME_OBJECT)
    }
  }, [resetTheme])

  return themeObject
}

export default styleUtil
