import React from 'react'

import { LotInterface } from '@api/types/house-and-land-type'

import ShortlistAction from './shortlist-action'

interface LotCardHeaderProps {
  lot: LotInterface
  totalPackage: number
  stageId: string
  precinctId: string
  isShortlisted: boolean
  totalShortlist: number
  availableStatusLabel: string
  statusColor: string
}

function LotCardHeader({
  lot,
  totalPackage,
  stageId,
  precinctId,
  isShortlisted,
  totalShortlist,
  availableStatusLabel,
  statusColor,
}: LotCardHeaderProps) {
  return (
    <div className="mb-2 flex justify-between gap-1">
      <div className="flex min-w-0 items-center">
        <div className="flex-1 truncate">
          <span className="text-[18px] font-bold leading-[21.5px]">
            Lot {lot.name}
          </span>
        </div>
        <span
          className="-mt-[2px] ml-4 h-2 w-2 rounded-full"
          style={{
            backgroundColor: statusColor,
          }}
        ></span>
        {lot.status === availableStatusLabel ? (
          <span
            className="ml-1 text-xs font-semibold uppercase leading-[14px] tracking-widest"
            style={{
              color: statusColor,
            }}
          >
            {totalPackage} {totalPackage > 1 ? 'Packages' : 'Package'}
          </span>
        ) : (
          <span
            className="ml-1 text-xs font-semibold uppercase leading-[14px] tracking-widest"
            style={{
              color: statusColor,
            }}
          >
            {lot.status}
          </span>
        )}
      </div>
      <ShortlistAction
        actionType="icon"
        lot={lot}
        stageId={stageId}
        precinctId={precinctId}
        isShortlisted={isShortlisted}
        totalShortlist={totalShortlist}
        availableStatusLabel={availableStatusLabel}
      />
    </div>
  )
}

export default LotCardHeader
