import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import {
  BedConfigInterface,
  HouseAndLandConfigurationInterface,
  PriceRange,
} from '@store/types'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

import { ProjectType } from '@utilities/adgroup-utilities/constants/common'

export interface LogoInterface {
  visible: boolean
  src: string
}

export interface MarkerColourInterface {
  available: string
  sold: string
  area: string
  levels: string
}

export interface SnaploaderConfigurationInterface {
  active: boolean
  sceneSnapId: string
  sceneModelId: string
}

export interface EnvisionVRConfigurationInterface {
  active: boolean
  url: string
  zoom: number
  rotateCameraBy: number
}
export interface EnvisionVRLevelsByBlockInterface {
  [key: string]: Array<string>
}

export interface ThemeConfigInterface {
  font: string
  mainColour: string
  tertiaryColour: string
  neutralColour: string
  secondaryColour: string
}

export interface SlideShowInterface {
  type: string
  intervalInSeconds: number
}

export interface GallerySettingsInterface {
  slideShow: SlideShowInterface
}

export type StatusLabels = {
  [key: string]: string
}

export type StatusColors = {
  [key: string]: string
}

export interface ConfigData {
  showcaseActive: boolean
  logo: LogoInterface
  longhand: boolean
  showPrice: boolean
  show_carousel_indicator: boolean
  carousel_indicator_location: string
  hide_sold_price: boolean
  hideFilter: boolean
  sidePanelTitle: string
  hideUnitInfoName: boolean
  hideInfoPanelAspect: boolean
  unitPrefix: string
  unitGalleryControlStyle: string
  onlyShowUnitGalleries: boolean
  unitGalleyTab: {
    active: boolean
    duration: number
  }
  snaploader: SnaploaderConfigurationInterface
}

export interface NavigationConfigInterface {
  active: boolean
  key: string
  label: string
  order: number
  sortable: boolean
}

export interface ConfigPayload {
  config: {
    project: ConfigData
  }
  prices: PriceRange
  markerColour: MarkerColourInterface
  inspace?: {
    url: string
    active: boolean
  }
  threeDViewer?: {
    url: string
    active: boolean
  }
  bedConfig: Array<BedConfigInterface>
  aspects: Array<string>
  envisionVr?: EnvisionVRConfigurationInterface
  theme: ThemeConfigInterface
  type: ProjectType
}

type ConfigType = {
  projectName: string
}

export const configApi = createApi({
  reducerPath: 'configApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getConfig: builder.query<ApiResponse<ConfigPayload>, ConfigType>({
      query: (params: ConfigType) => ({
        url: `/v1/${params.projectName}/config`,
        method: 'get',
      }),
    }),
  }),
})

const mapConfigInfo = ({
  longhand,
  showPrice,
  show_carousel_indicator,
  carousel_indicator_location,
  hide_sold_price,
  hideFilter,
  sidePanelTitle,
  hideUnitInfoName,
  hideInfoPanelAspect,
  unitPrefix,
  unitGalleryControlStyle,
  onlyShowUnitGalleries,
  unitGalleyTab,
  logo,
}: ConfigData) => ({
  longhand,
  showPrice,
  showCarouselIndicator: show_carousel_indicator,
  carouselIndicatorLocation: carousel_indicator_location,
  hideSoldPrice: hide_sold_price,
  hideFilter,
  sidePanelTitle,
  hideUnitInfoName,
  hideInfoPanelAspect,
  unitPrefix,
  unitGalleryControlStyle,
  onlyShowUnitGalleries,
  controls: unitGalleyTab,
  logo,
})

const mapThreeDViewerConfig = (myData: any) => {
  if ('threeDViewer' in myData) {
    return myData.threeDViewer
  }
  return myData.inspace || {}
}

export const selectFromResult = ({
  data,
  isLoading,
  error,
  status,
}: UseQueryStateResult<any, any>): {
  configInfo: Partial<ReturnType<typeof mapConfigInfo>>
  prices: PriceRange
  markerColour: MarkerColourInterface
  threeDViewer: { url: string; active: boolean }
  snaploaderConfiguration: SnaploaderConfigurationInterface
  envisionVRConfiguration: EnvisionVRConfigurationInterface
  bedConfig: Array<BedConfigInterface>
  houseAndLandConfig: HouseAndLandConfigurationInterface
  aspects: Array<string>
  navigationSettings: Array<NavigationConfigInterface>
  unitTypes: Array<string>
  gallerySettings: GallerySettingsInterface
  theme: ThemeConfigInterface
  disableSoldUnit: boolean
  type: ProjectType
  isLoaded: boolean
  hasError: boolean
  status: string
  lightUpBuildingModelOnFilter: boolean
  statusLabels: StatusLabels
  statusColors: StatusColors
} => ({
  configInfo: data?.data.config.project
    ? mapConfigInfo(data?.data.config.project)
    : {},
  prices: data?.data.prices || ({} as PriceRange),
  markerColour: data?.data.markerColour || {},
  threeDViewer: mapThreeDViewerConfig(data?.data || {}),
  snaploaderConfiguration: data?.data.config.project.snaploader || {},
  envisionVRConfiguration:
    data?.data.envisionVr || ({} as EnvisionVRConfigurationInterface),
  bedConfig: data?.data.bedConfig || [],
  houseAndLandConfig: data?.data.houseAndLandConfig || {},
  aspects: data?.data.aspects || [],
  navigationSettings: data?.data.navigationSettings || [],
  unitTypes: data?.data.unitTypes || [],
  gallerySettings: data?.data.gallerySettings || {},
  theme: data?.data.theme || {},
  disableSoldUnit: data?.data.disableSoldUnit || false,
  type: data?.data.type || ProjectType.Apartment,
  isLoaded: !isLoading,
  hasError: error !== undefined,
  status,
  lightUpBuildingModelOnFilter:
    data?.data.lightUpBuildingModelOnFilter || false,
  statusLabels: data?.data.statusLabels || {},
  statusColors: data?.data.statusColours || {},
})
export const { useGetConfigQuery, useLazyGetConfigQuery } = configApi
